import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, Typography } from "@mui/material"

interface BillableItem {
  name: string
  short: number
  mid: number
  long: number
}

const catalog: BillableItem[] = [
  {
    name: 'Mytí + foukaná',
    short: 350,
    mid: 550,
    long: 750,
  },
  {
    name: "Střih + foukaná",
    short: 750,
    mid: 950,
    long: 1300,
  },
  {
    name: "Barva + foukaná",
    short: 1200,
    mid: 1550,
    long: 2100,
  },
  {
    name: "Barva + střih",
    short: 1550,
    mid: 1900,
    long: 2450,
  },
  {
    name: "Melír + foukaná",
    short: 1550,
    mid: 2000,
    long: 2400,
  },
  {
    name: "Melír + střih",
    short: 1900,
    mid: 2350,
    long: 2750,
  },
  {
    name: "Žehlení, Kulmování",
    short: 250,
    mid: 300,
    long: 400,
  },
]

export function PriceList(props: {}) { // TODO rename to ChoosePlan
  return <Paper variant="outlined">
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell>Quantity</TableCell> */}
            <TableCell align="right"></TableCell>
            <TableCell align="right">Krátké</TableCell>
            <TableCell align="right">Střední</TableCell>
            <TableCell align="right">Dlouhé</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {catalog.map((o, i) => (
            <TableRow key={i}>
              <TableCell sx={{ fontSize: '1.0em' }} component="th" scope="row"><strong>{o.name}</strong></TableCell>
              <TableCell sx={{ fontSize: '1.0em' }} align="right"><strong>{o.short},-</strong></TableCell>
              <TableCell sx={{ fontSize: '1.0em' }} align="right"><strong>{o.mid},-</strong></TableCell>
              <TableCell sx={{ fontSize: '1.0em' }} align="right"><strong>{o.long},-</strong></TableCell>
            </TableRow>
          ))}
        </TableBody>
				<TableFooter><TableRow><TableCell colSpan={4}><Typography style={{textAlign: "right"}}>Extra dlouhé: příplatek 500,- na všechny služby.</Typography></TableCell></TableRow></TableFooter>
      </Table>
    </TableContainer>
  </Paper>
}
