import { ImageList, ImageListItem } from "@mui/material";
import imgG10 from '../assets/2021-12-31-5-wip.jpg'
import imgIn from '../assets/m8.jpg'
import imgL1 from '../assets/l1.jpg'
import imgM6 from '../assets/m6.jpg'
import imgM10 from '../assets/m10.jpg'
import imgWindow from '../assets/m7.jpg'
import img101 from '../assets/101.min.jpg'
import img102 from '../assets/102.min.jpg'
import img103 from '../assets/103.min.jpg'
import img105 from '../assets/105.jpg'
import img106 from '../assets/106.min.jpg'
import img107 from '../assets/107.min.jpg'
import img108 from '../assets/108.jpg'
import img109 from '../assets/109.min.jpg'
import img110 from '../assets/110.min.jpg'

export function ImageBox(props: {}) {
  return <ImageList sx={{ width: [500, 550, 600], height: [840, 900, 1010] }} cols={3} rowHeight={164}>
    {itemData.map((item) => (
      <ImageListItem key={item.img}>
        <img
          src={`${item.img}`}
          loading="lazy"
        />
      </ImageListItem>
    ))}
  </ImageList>

}

const itemData = [
  { img: img101, },
  { img: img102, },
  { img: img103, },
  { img: img110, },
  { img: img105, },
  { img: img106, },
  { img: img107, },
  { img: img108, },
  { img: img109, },
  { img: imgG10, rows: 2, cols: 2 },
  { img: imgM6 },
  { img: imgWindow, },
  { img: imgM10, rows: 2, cols: 2 },
  { img: imgIn, },
  { img: imgL1, },
];

