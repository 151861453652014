import imgNora from '../assets/nora.jpg'
import React from 'react'
import { Box, Stack, styled, Typography } from "@mui/material";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const googleLogo = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAABGlBMVEX////pQjU0qFNChfT6uwUufPTk7f6+0vz6uQD6/P80fvPoKBL/vAAtpk7pPzLoLBn5zMnoNiYRoT8eo0XrU0j62dc8gvTz9/56wov0qKTxjYfubWTrT0PoMiHoOiv97u3sXFL85uX1tLDoOjb7wQD+6LxPjfXzuw32+/e838TU69kzqkBwvoLveHDwhH7zn5rnHQD3vbr72878yDn2oA7+8tjrVDH8z2jvcin0kx7+4qn4rRH8xkftYi7/+/LyhCT91XgPc/PV4v3914eaufl7p/hqm/b/zVyIrCtYq0zWuCNIr2OosjZyrUaMsfjkuRq/tS2NsD+Pypyvx/rD3OQ4m5w2o3Gm1LA/jdY7l7I4oINAiuQ9ksRctnLLu7JUAAAEOUlEQVRoge2XeXebRhTF8UjEshAgQJK1geQliRbLieu4bh3HtbO2VZq2ZFGbRN//a2TASBHz3qAZID45J75/+jD8dO9bBivKrW71o6hXbbU7Xcuk8rqddqu6kzdgd+KaruUZxkYow/As+ofJbi83QqumWd4GIs/Saq08OPWJZhkYIfJkaZN6RkS1b6IeYn7MfhZMvW8mmFixY3ZSd8GhJoQI3WgPUiGq7tqgVmV1U5hpi9uIMtN2JRG9viWHCGQeSjF28LlYJ6sjwaiLNRXUw6owo6qlQ2xo4oz6DTB2zG/P6Hkp6yHBUPr8vjI8N1j4wZJ3wU+RYbR582FYZq19VA8Xe69+1K6ZseUsw+A1lmF22Yuj1+q6RhqGYqAFMcw++pJqzU3BwMPyPO47dkMzUowdNKzElRQsOSmG0kHCMrSj5EOHEruE6idkDA137dUqd/ceP9qDjJy/rk5K+6WfGYyZ9UuE1WmpWNz/JUYx19RDWheUQSm/rlCsds4M5SyEFPcfbywwRjdvhnJ+DaF6sveNChKldW3mt5DiTfJmLNK6pjzaoxgz7/8NFOVyBVIMetmT+fQQVHEVEvSyK7UthHQRZwS9zHv0jqC2wMkTFlK65DC2rlQhXT0FR88A5C4PohaEVN4ER58ByEVWyHNw9BULKXIYwpDGC3D0nIGUzjNDXoKj91nIaVZI4R44us9CLjNDCuAoW5HSs8yQxk1A1JuIC0KO8y88jAu08HFmCCz8KZj4zBDYwnCtnGSEIMN4F0DOskLgWoGr/hUPclVGxUKQBamwha/8PuBANnE1WAhc9UwPV+7/4Qw5VnBtsymqd+BDscpX/iREn0pBNtm8VHj9xipfeU2onJEM5AUbF+xgZaUoleJfAUPOCkir8QZ7bDGOlb9JJJmqPGfTwuq+zKvyD1nK4TQY1BYbVkHdRh8shZ3771cG0eeikDesEWTeQ9H+op1LVuX4YoynYAuU3+JPXpSCzo3LFirLFovgpkVL/5plCFLugYrw0lKUgQ0hxJ6tZbwEe6ugwm/UhXwdoTjNZMQ29MGZxMiKg0CITpJG/90VwsCHJNIMpRC7yRuYwcH4PbxfGglGqKZYYNSM42OYUdPWyfhDgfWSUJHwl+FWKMaeD+Oc0Wxqhz9J1/+LVx65E+MaYh0WubGnzdlwRDWc+XPdcZaux//HIuPOyFJNnpdrUCQ9Huv4Y+NrZGpS1SPN8bIkS59+WkRWXhdWoAFJRRl/VkU6KxuFjN+Xg8jKawuSieJ8oLOPfT7kSqG9LM6gmif1GJ8ylmHQXcmdlwQGEb6uIw0d2cicA0kE1UAuMl3g4sHNiGPsuWxUS/mCmTlE7sM5roG/3o2eDRFqRuwEO3Q3H0h9M/M08omN5aZTwnyWuhaQM2tSEN3wCzmObc/9YX6ESIPgsmoeUDV9ennl/v5b3ep71Rdu9XcWxVG+RgAAAABJRU5ErkJggg=="

const StarRating = (props: { rating: number }) => {
  const normalizedRating = Math.min(5, Math.max(0, props.rating));
  const filledPercentage = (normalizedRating / 5) * 100;

  return (<a style={{color: "#4285f4"}} href="https://www.google.com/search?q=Salon+Nora+Bosner%2C+V%C3%A1clavsk%C3%A9+n%C3%A1m.+793%2F36%2C+110+00+Nov%C3%A9+M%C4%9Bsto%2C+Czechia#lrd=0x470b9594c5a3bbd3:0x79df64ab90c07eb4,1,,,,">
    <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Arial', fontSize: '24px', fontWeight: "bold" }}>
			<img style={{width: "28px", height: "28px"}} src={googleLogo} alt="Google" />
			&nbsp;<span style={{ marginRight: '8px', color: 'gold' }}>{normalizedRating.toFixed(1)}</span>
			<div style={{ position: 'relative', display: 'flex' }}>
			  <div style={{ color: 'lightgray', display: 'flex' }}>
			    {'★★★★★'}
			  </div>
			  <div style={{
			    position: 'absolute',
			    top: 0,
			    left: 0,
			    overflow: 'hidden',
			    width: `${filledPercentage}%`,
			    color: 'gold',
			    display: 'flex'
			  }}>
			    {'★★★★★'}
			  </div>
			</div>
    </div></a>
  );
}

const ratingHtml = `<a href="https://www.firmy.cz/detail/13342961-kadernictvi-nora-bosner-praha.html#hodnoceni" target="_blank" rel="noopener"><img src="https://www.firmy.cz/img-stars/light-13342961.svg" alt="Kadeřnictví - Nora Bosner na Firmy.cz" /></a>`;

export function Intro() {
	return <React.Fragment>
		<Stack style={{marginTop: "30px"}} direction={{ xs: 'column', md: 'row' }} id="o-mne">
		  <Item><img width={250} src={`${imgNora}`} loading="lazy" /></Item>
		  <Item width="100%" alignSelf="center" style={{backgroundColor: "white"}}>
		    <Typography variant="h3" pt={2} style={{ fontSize: "2.2em", color: "#333" }}>Kadeřnické služby v srdci Prahy</Typography>
		    <Typography variant="h3" pt={2} style={{ fontSize: "2.2em", color: "#333" }}>Nora Bosner</Typography>
		    <Typography variant="h3" pt={2} style={{ fontSize: "2.2em", fontWeight: "bold", color: "#333" }}>Václavské náměstí 17</Typography>
		    <Typography variant="h3" pt={1} style={{ fontSize: "2.0em", fontWeight: "bold", color: "#333" }}>vchod skrz obchod Van Graaf</Typography>
				<div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
					<div style={{justifyContent: "center"}} >{StarRating({rating: 4.8})}</div>
					<div dangerouslySetInnerHTML={{__html: ratingHtml}} />
				</div>
		    {/*<Typography variant="h3" pt={2} pb={2} width="100%" style={{ fontSize: "2.6em", color: "#fddc5c", background: "black" }}>Václavské náměstí 793/36</Typography>*/}
		    {/*<Typography variant="h3" style={{ fontSize: "2.6em", color: "#333" }}>Nora Bosner poskytuje kadeřnické služby v centru Prahy na adrese <a href="https://goo.gl/maps/yJXsfj7XghZkF6qq6" target="_blank">Václavské náměstí 793/36</a>.</Typography>*/}
		  </Item>
		</Stack>
		<Typography mt={3} variant="h3" style={{ fontSize: "1.5em", fontWeight: "bold", color: "#333", textAlign: "left" }}>Kde mne najdete</Typography>
		<Typography mt={1} variant="body1" style={{ fontSize: "1.2em", color: "#333", textAlign: "left" }}>Prostorný salon, po boku střední části Václavského náměstí uvnitř Jindřišské pasáže, nabízí příjemnou atmosféru a kvalitu služeb českého kadeřníka.</Typography>
		<Typography mt={3} variant="h3" style={{ fontSize: "1.5em", fontWeight: "bold", color: "#333", textAlign: "left" }}>Technologie služeb</Typography>
		<Typography mt={1} variant="body1" style={{ fontSize: "1.2em", color: "#333", textAlign: "left" }}>V účesové tvorbě používám vlasovou kosmetiku značky BES Beauty &amp; Science. Produkty vysoké kvality „Made in Italy” neobsahují parabeny a nejsou testovány na zvířatech. Včelí vosky nahrazují chemická zahušťovadla a barvy BES jsou díky sníženému obsahu amoniaku šetrné k vlasům i pokožce.</Typography>
		<Typography mt={1} variant="body1" style={{ fontSize: "1.2em", color: "#333", textAlign: "left" }}>Střih upravuji nůžkami Kasho nejvyšší třídy Damascus Series, vyrobené z damaškové oceli V10 v Japonsku. Maximální ostrost a vysoce leštěné konvexně broušené čepele zaručují hladký řez technikou klasického i klouzavého střihu.</Typography>
		<Typography mt={1} variant="body1" style={{ fontSize: "1.2em", color: "#333", textAlign: "right" }}>Řemeslo žije!<br />Těším se na Vaši návštěvu<br />Nora Bosner</Typography>
	</React.Fragment>
}
