import { AppBar, Box, Button, Card, CardActions, CardContent, CardMedia, Container, CssBaseline, IconButton, ImageList, ImageListItem, Link, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import imgNora from './assets/nora.jpg'
import { Header } from './components/header'
import { Map } from './components/map'
import { ImageBox } from './components/gallery'
import { PriceList } from './components/pricelist'
import { Intro } from './components/intro'
import { InfoBox } from './components/infobox'
import { ServiceBox } from './components/servicebox'

function H2(props: {children: any, id: string}) {
  return <React.Fragment>
    <Typography id={props.id} variant="h2" style={{fontSize: "1.5rem", lineHeight: "1.5", margin: "40px 0 4px"}}>{props.children}</Typography>
  </React.Fragment>
}

function App() {
  return <Box sx={{
    'a': {
      textDecoration: 'none',
      color: '#0645ad'
    }
  }}>
    <CssBaseline />
    <Header />
    <Container maxWidth="md" sx={{ typography: 'body1', flexGrow: 1 }}>
      <Intro />
      <H2 id="cenik">Ceník</H2><div id="ceny"></div>
      <PriceList />
      <H2 id="galerie">Galerie</H2>
      <Box mt={2} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}><ImageBox /></Box>
      <H2 id="kontakt">Kontakt</H2>
      <InfoBox />
      <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }} ><Map /></Box>
    </Container>
  </Box>
}

export default App;
