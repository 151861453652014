import logo from '../assets/logo192.png'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, AppBar, Toolbar, Container, Stack, Typography, Button, IconButton, Menu, MenuItem, Link } from "@mui/material";
import React from "react";

export function Header(props: {}) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  }

  return <Box sx={{ flexGrow: 1 }}>
    <AppBar style={{color: "black", backgroundColor: "transparent"}} position="static">
      <Toolbar style={{backgroundColor: "transparent", padding: "unset"}} variant="dense">
        <Container maxWidth="md" sx={{padding: "unset", typography: 'body1', flexGrow: 1}}>
          <Box style={{width: "100%", textAlign: "left"}}>
            <Box style={{display: 'flex', alignItems: 'center'}}>
              <img width={88} src={logo} />
							<Stack direction="column">
								<Box><Typography sx={{fontSize: ['1.3rem', '1.4rem'], paddingLeft: ['0.4rem', '1.6rem']}} mb={1} variant="h2" color="inherit" component="div" pt={2}>Kadeřnictví <a style={{color: "black"}} href="tel:+420-731-809-231">+420 731 809 231</a></Typography></Box>
								<Box><Typography sx={{fontSize: ['1.3rem', '1.4rem'], paddingLeft: ['0.4rem', '1.6rem']}} mb={1} variant="h2" color="inherit" component="div" pt={[0, 0]}>Objednávky Po–Pá 8:00–20.00</Typography></Box>
							</Stack>
              <div style={{flex: '1 1 auto'}} />
              <Box sx={{display: {xs: 'none', md: 'block'}, '& a': {color: 'black'}}}>
                <Button sx={{ mt: '8px'}} href="#cenik">Ceník</Button>
                <Button sx={{ mt: '8px'}} href="#galerie">Galerie</Button>
                <Button sx={{ mt: '8px'}} href="#kontakt">Kontakt</Button>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="site menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  style={{marginLeft: 'auto'}}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                    '& a': {color: 'black', textDecoration: 'none'}
                  }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography href="#sluzby" textAlign="center" component={Link}>Služby</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography href="#cenik" textAlign="center" component={Link}>Ceník</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography href="#galerie" textAlign="center" component={Link}>Galerie</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography href="#kontakt" textAlign="center" component={Link}>Kontakt</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  </Box>
}
